const DOMAIN = 'https://api.yan226.top';
// const DOMAIN = 'http://local.tools.com';
const apiRoute =   {
    domain: DOMAIN,

    word2pdf: DOMAIN + '/api/v1/index/word2pdf',
    pdf2word: DOMAIN + '/api/v1/index/pdf2word',
    pdf2img: DOMAIN + '/api/v1/index/pdf2img',
    img2clear: DOMAIN + '/api/v1/index/img2clear',


};

export default apiRoute;