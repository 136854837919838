<template>
  <div id="navbar">
    <!--网站Logo 开始-->
    <div class="WebLogo">
      <a href="/" target="_self">
        <img src="/static/images/favicon.png" title="小工具集合" alt="小工具集合">
      </a>
    </div>
    <!--网站Logo 结束-->
    <!--导航 开始-->
    <div id="navigation">
      <ul class="navigationlist">
        <li>
          <a href="/" data-pid="" target="_self" :class="pIndex == 'word2pdf' ? 'current' : ''">WORD转PDF</a>
        </li>
        <li>
          <a href="/pdf-word" target="_self" data-id="1" :class="pIndex == 'pdf2word' ? 'current' : ''">PDF转WORD</a>
        </li>
        <li>
          <a href="/pdf-img" target="_self" data-id="2" :class="pIndex == 'pdf2img' ? 'current' : ''">PDF转图片</a>
        </li>
        <li>
          <a href="/img-clear" target="_self" data-id="3" :class="pIndex == 'gfpgan' ? 'current' : ''">图片变清晰</a>
        </li>
      </ul>
    </div>
    <!--导航 结束-->
  </div>
</template>

<script>
export default {
  props:['pIndex'],
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header"
}
</script>

<style scoped>

</style>