import Vue from "vue"
import VueRouter from  'vue-router'

Vue.use(VueRouter)
import Word2pdf from '../components/Word2pdf.vue'
import Pdf2word from '../components/Pdf2word.vue'
import Pdf2img from '../components/Pdf2img.vue'
import Gfpgan from "@/components/Gfpgan.vue"
const routes = [
    {
        path: '/',
        name: 'Word2pdf',
        component: Word2pdf,
        meta: {
            title: 'WORD转PDF',
            keywords: 'WORD转PDF',
            description: 'WORD转PDF'
        }
    },
    {
        path: '/pdf-word',
        name: 'Pdf2word',
        component: Pdf2word,
        meta: {
            title: 'PDF转WORD',
            keywords: 'PDF转WORD',
            description: 'PDF转WORD'
        }
    },
    {
        path: '/pdf-img',
        name: 'Pdf2img',
        component: Pdf2img,
        meta: {
            title: 'PDF转图片',
            keywords: 'PDF转图片',
            description: 'PDF转图片'
        }
    },
    {
        path: '/img-clear',
        name: 'Gfpgan',
        component: Gfpgan,
        meta: {
            title: '图片变清晰',
            keywords: '图片变清晰',
            description: '图片变清晰'
        }
    },
]

const router = new  VueRouter({
    routes,
    mode: 'history'
})
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Default Title';
    const meta = document.querySelector('meta[name="keywords"]');
    if (meta) {
        meta.setAttribute('content', to.meta.keywords || 'default,keywords');
    } else {
        const newMeta_keywords = document.createElement('meta');
        newMeta_keywords.setAttribute('name', 'keywords');
        newMeta_keywords.setAttribute('content', to.meta.keywords || 'default,keywords');
        const newMeta_description = document.createElement('meta');
        newMeta_description.setAttribute('name', 'description');
        newMeta_description.setAttribute('description', to.meta.description || 'default,description');
        document.head.appendChild(newMeta_keywords);
        document.head.appendChild(newMeta_description);
    }
    next();
});
export default router