<template>
  <!-- 底部版权 开始-->
  <div id="copyright_main">
    <div id="copyright">
      <span class="TechnicalSupport">技术支持:<a href="/" target="_blank">小工具集合</a></span>
      <span>
            <p style="display: inline"> &nbsp;版权所有:</p>
            <a href="https://beian.miit.gov.cn/">赣ICP备2023012252号</a>
        </span>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer"
}
</script>

<style scoped>

</style>