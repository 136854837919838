<template>
  <div class="content">
    <Header-com :pIndex=" 'pdf2img' "></Header-com>

    <div class="main_con">
      <form ref="uploadForm" enctype="multipart/form-data" class="upload-form" @submit.prevent="submitFile">
        <div v-if=" res_success ">
          <div class="select_icon">
            <span class="select_icon_span1">
              <img class="select_icon_img1" alt="icon" src="/static/images/pic-jpg.svg" >
            </span>
          </div>
          <div class="select_text">
            <div class="select_text_illustrate1">{{ pdf_name }}</div>
            <div class="select_text_illustrate2">
              <span class="svg-wrap">
                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm3.592 5.65a.6.6 0 0 0-.849-.01l-3.716 3.628-1.453-1.39-.084-.068a.6.6 0 0 0-.746.935l1.872 1.792.083.066a.6.6 0 0 0 .751-.07l4.132-4.034.07-.082a.6.6 0 0 0-.06-.766Z" fill="currentColor" fill-rule="evenodd"></path></svg>
              </span>
              <span class="svg-text">转换成功</span>
            </div>
          </div>
          <div class="">
            <span class="ant-btn"><a v-on:click="change_success()">继续上传</a></span>
            <span class="ant-btn ant-btn-primary"><a :href="pdf_url">下载结果</a></span>
          </div>

        </div>
        <div v-else>
          <div class="select_icon">
            <span class="select_icon_span1">
              <img class="select_icon_img1" alt="icon" src="/static/images/pic-pdf.svg" >
            </span>
            <span class="select_icon_span3">
                <img alt="icon" src="/static/images/icon-exchange.svg" >
            </span>
            <span class="select_icon_span1">
              <img class="select_icon_img1" alt="icon" src="/static/images/pic-jpg.svg">
            </span>
          </div>
          <div class="select_text">
            <div class="select_text_illustrate1">点击上传文件 / 拖拽文件到此处</div>
            <div class="select_text_illustrate2">可支持<!-- -->pdf<!-- -->格式文件</div>
            <div class="select_text_illustrate3">上传单个文件大小不超过<!-- -->20<!-- -->M</div>
          </div>
          <!--      <div class="select_times">-->
          <!--        <span>今日可用：</span><span class="select_times_num">3次</span>-->
          <!--      </div>-->
          <!--          1.accept=”application/msexcel”-->
          <!--          2.accept=”application/msword”-->
          <!--          3.accept=”application/pdf”-->
          <!--          4.accept=”application/poscript”-->
          <!--          5.accept=”application/rtf”-->
          <!--          6.accept=”application/x-zip-compressed”-->
          <!--          7.accept=”audio/basic”-->
          <!--          8.accept=”audio/x-aiff”-->
          <!--          9.accept=”audio/x-mpeg”-->
          <!--          10.accept=”audio/x-pn/realaudio”-->
          <!--          11.accept=”audio/x-waw”-->
          <!--          12.accept=”image/gif”-->
          <!--          13.accept=”image/jpeg”-->
          <!--          14.accept=”image/tiff”-->
          <!--          15.accept=”image/x-ms-bmp”-->
          <!--          16.accept=”image/x-photo-cd”-->
          <!--          17.accept=”image/x-png”-->
          <!--          18.accept=”image/x-portablebitmap”-->
          <!--          19.accept=”image/x-portable-greymap”-->
          <!--          20.accept=”image/x-portable-pixmap”-->
          <!--          21.accept=”image/x-rgb”-->
          <!--          22.accept=”text/html”-->
          <!--          23.accept=”text/plain”-->
          <!--          24.accept=”video/quicktime”-->
          <!--          25.accept=”video/x-mpeg2”-->
          <!--          26.accept=”video/x-msvideo”-->
          <!--          -->
          <input type="file" name="file" id="file" accept="application/pdf" class="input-file" ref="file" @change="handleFileChange" style="display: none"/>
          <!-- 进度条展示 -->
          <p v-if="showProgress">上传进度：{{ percent }} %</p>
          <label for="file" class="select_file">
            <span class="button_text" :disabled="!selectedFile">上传本地文件</span>
          </label>
        </div>
      </form>
    </div>

    <Footer-com></Footer-com>
  </div>
</template>

<script>
import HeaderCom from "@/components/common/Header";
import FooterCom from "@/components/common/Footer";
import axios from 'axios';
import apiRoute from "@/config/apiRoute";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Pdf2img",
  components: {
    HeaderCom,
    FooterCom
  },
  data () {
    return {
      name: 'Pdf2img',
      selectedFile: null,  // 选中的文件
      showProgress: false,  // 是否展示上传进度条
      percent: 0,    // 上传进度百分比
      res_success: false, //是否返回结果
      pdf_name: '',
      pdf_url: '',
    }
  },
  methods:{
    change_success: function (){
      this.res_success = false;
    },
    handleFileChange: function (event) {
      this.selectedFile = event.target.files[0];
      this.submitFile()
    },
    submitFile: function () {
      if (!this.selectedFile) return;
      // 新建 from 对象
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);

      this.showProgress = true;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          // 'Content-Type': 'application/json',
        },
        onUploadProgress: progressEvent => {
          // 计算上传进度百分比
          this.percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
      };
      axios.post(apiRoute.pdf2img, formData, config)
          .then((res) => {
            if(res.data.code === 0){
              this.showProgress = false;  // 移除 progress 条
              this.res_success = true;
              this.pdf_name = res.data.data.name;
              this.pdf_url = res.data.data.fullurl;
            }
            console.log(res.data);

          })
          .catch((error) => {
            console.log(error);
            this.showProgress = false;
          });
    }
  }
}
</script>

<style scoped>

</style>